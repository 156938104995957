<template>
  <div id="email-log">
    <v-container fluid>
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>

      <!-- table -->
      <customTable :tableProps="tableProps" class="ctable"></customTable>
    </v-container>
  </div>
</template>

<script>
// import config from "@/config";
// import axios from "axios";
import Mixins from "@/mixin";
import customTable from "@/components/custom-table";
import emailapi from "@/services/email_api";
import moment from "moment";

export default {
  name: "emaillog",
  mixins: [Mixins],
  components: {
    customTable
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "Email logs",
          disabled: false,
          to: { name: "email-logs" },
          exact: true
        }
      ],
      // table props
      tableProps: {
        loading: false,
        tableHeader: "Email logs",
        headerChip: null,
        headerButton: false,
        headerButtonLabel: "email",
        headerListLabel: "Filter by mail id :",
        headerList: [],
        index: true,
        action_button_view: true,
        action_button_edit: true,
        action_button_delete: true,
        headers: [
          { text: "S.No", align: "left", value: "index", sortable: false },
          { text: "Mail to", align: "center", value: "to_mail_id" },
          {
            text: "Mail type",
            align: "center",
            value: "mail_type",
            sortable: false
          },
          {
            text: "Mail Details",
            align: "center",
            value: "to_subject",
            sortable: false
          },
          { text: "Sent Time", align: "center", value: "sent_time" }
        ],
        items: []
      }
    };
  },

  methods: {},
  async mounted() {
    let response = await emailapi.get_email_log();
    if (response.status != 200)
      this.alert("error", "", "Error in getting data");
    let maillogs = response.data.mails;
    maillogs = await maillogs.map(obj => {
      obj.sent_time = moment(String(obj.sent_time)).format(
        "MM/DD/YYYY hh:mm a"
      );
      return obj;
    });
    console.log(maillogs);
    this.tableProps.items = maillogs;
    this.tableProps.headerChip = `${response.data.count} mails this month`;
    this.tableProps.headerList = response.data.mails.map(x => x.to_mail_id);
  }
};
</script>

<style>