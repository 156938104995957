import axios from 'axios'
import config from '../config'

export default {
    // get email log api
    async get_email_log() {
        let response =  await axios.get(`${config.emailAPIDomain}/mail-logs`, {
            headers: {
                "x-access-token": `${config.EMAIL_ENGINE_TOKEN}`
            }
        });
        return response;
    },

}